import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { getPatientTimezone, PatientContext } from '../../../../contexts/PatientContext';
import { getVitalsScores } from '../../../../services/api/chart';
import { HrChart } from './hr-chart';
import { HrvChart } from './hrv-chart';
import { RespirationChart } from './respiration-chart';
import { getCurrentVitals } from '../../../../services/api/firestore';
import firebase from 'firebase/app';
import { PractitionerContext } from '../../../../contexts/Practitioner';

type Props = {
  startDate: string;
  endDate: string;
  location?: string;
};

const VitalsContainer: FunctionComponent<Props> = ({ startDate, endDate, location }) => {
  const [vitals, setVitals] = useState<Array<{ ts: number; hr: number; hrv: number; rr: number }>>([]);
  const { patient } = useContext(PatientContext);
  const patientTimezone = getPatientTimezone();
  const { practitioner } = useContext(PractitionerContext);
  const GRAPH_WIDTH_PX = 1080;
  let unsubscribeVitals: () => void = () => {};

  const getAndParseScores = async () => {
    const { data } = await getVitalsScores(patient!.id, Math.floor(GRAPH_WIDTH_PX / 4), {
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).tz(patientTimezone).format('YYYY-MM-DD'),
      timezone: patientTimezone,
    });
    return (
      data?.points?.map(p => ({
        ...p,
        ts: moment.utc(p.ts).unix() * 1000,
      })) || []
    );
  };

  const requestVitals = async () => {
    let points = await getAndParseScores();

    unsubscribeVitals = getCurrentVitals(patient?.firebaseUid!, {
      next: async (Snapshot: firebase.firestore.DocumentSnapshot) => {
        if (Snapshot) {
          const current = Snapshot.data();
          const { timestamp, hr, rr, hrv } = current as {
            hr: number;
            hrv: number;
            rr: number;
            timestamp: firebase.firestore.Timestamp;
          };
          if (!!timestamp && !!hr) {
            points = await getAndParseScores();
            const ts = moment(timestamp.seconds * 1000)
              .tz(patientTimezone)
              .format();
            // Need to have the points ordered by date so later I can easily get the latest sleep entry and compare it to the firebase current
            // if the firebase current is the same or earlier?than the last sleep entry, ignore it.
            const orderedPoints = points.sort((a, b) => moment(a.ts).unix() - moment(b.ts).unix());
            if (
              ts >= startDate &&
              ts <= endDate &&
              moment(timestamp.seconds * 1000).isAfter(orderedPoints.slice(0).pop()?.ts)
            ) {
              setVitals(prevState => [
                ...(points.length ? points : prevState),
                {
                  ts: moment.utc(timestamp.seconds * 1000).unix() * 1000,
                  hr,
                  rr,
                  hrv,
                },
              ]);
            } else if (points.length) {
              setVitals(points);
            }
          } else {
            setVitals(points);
          }
          points = [];
        }
      },
    });
  };
  useEffect(() => {
    (async (): Promise<void> => {
      if (!patient) {
        return;
      }
      await requestVitals();
    })();

    return (): void => {
      unsubscribeVitals();
    };
  }, [patient, startDate, endDate]);
  return (
    <>
      {/* <RespirationChart
        data={vitals.flatMap(p => (p.rr ? [{ ts: p.ts, value: p.rr }] : []))}
        start={startDate}
        end={endDate}
      /> */}
      <HrChart data={vitals.flatMap(p => (p.hr ? [{ ts: p.ts, value: p.hr }] : []))} start={startDate} end={endDate} />
      <HrvChart
        data={vitals.flatMap(p => (p.hrv ? [{ ts: p.ts, value: p.hrv }] : []))}
        start={startDate}
        end={endDate}
        location={location}
      />
    </>
  );
};

export default VitalsContainer;
